<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #4caf50" min-height="128">
    <MainModal
        :main="{ component: 'Contact', title: 'Контактні дані' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_contact)"
        :modal="show_contact_dialog"
        @updateItemModal="contactUpdateItemModal"
    />
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Контакти
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom color="success" v-if="flat.flat_owner">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openContactCreateDialog" class="grey lighten-4 mr-1"
                   v-bind="attrs"
                   v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити новий контакт</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-title>
    <v-list class="pt-0 pb-0">
      <v-divider></v-divider>
      <template v-for="(item, index) in contacts">
        <v-list-item  :key="`itm-${index}`" style="min-height: 36px" @click="onContactItemClick(item)">
          <v-list-item-avatar size="26">
            <v-icon size="20" color="white" :class="getIcon(item.contact_type, 'color')">
              {{ getIcon(item.contact_type, 'type') }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content class="subtitle-1 py-1">
            <v-list-item-title style="font-size: .89rem">
              {{ item.contact_value }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.short_name }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider :key="`vd-${index}`"></v-divider>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {FETCH_MAIN_CONTACTS} from "@/store/actions/contact";

export default {
  name: "HWP_Contacts",
  components: {
    MainModal
  },
  props: {
    inner_person_hash: {
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    ...mapGetters({
      contacts: 'getContacts',
      flat: 'getFlat'
    })
  },
  data() {
    return {
      selected_contact: {},
      show_contact_dialog: false
    }
  },
  methods: {
    ...mapActions({
      fetchMainContacts: FETCH_MAIN_CONTACTS
    }),
    getIcon(payload, key) {
      let payload_trim = payload.trim()

      let values = {
        type: {
          'phone': 'mdi-phone',
          'email': 'mdi-email'
        },
        color: {
          'phone': 'success',
          'email': 'success'
        }
      }

      const valKey = values[key]
      if (!valKey) return ''
      return valKey[payload_trim] || (key === 'color' ? 'grey darken-3' : 'mdi-image-filter-none')
    },
    contactUpdateItemModal() {
      this.show_contact_dialog = false
      this.selected_contact = {}
    },
    openContactCreateDialog() {
      this.selected_contact = {
        family_hash: this.flat.flat_owner.person_hash,
        contact_main: true,
        person_hash: this.flat.person_hash,
        contact_type: 'phone'
      }
      this.show_contact_dialog = true
    },
    onContactItemClick(payload) {
      this.selected_contact = JSON.parse(JSON.stringify(payload))
      this.show_contact_dialog = true
    },
  },
  created() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
    this.fetchMainContacts(person_hash)
  }
}
</script>

<style scoped>

</style>